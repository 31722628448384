import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showToastAlert } from "../redux/action/alert";
import { succeedToSubmit, failToSubmit } from "../common/alert";
import { submitLoginForm } from "../api/login";
import Layout from "../components/layout/Layout";
import { getCookie } from "../common/auth";
import { clearCart } from "../redux/action/cart";
import { getUserInfo } from "../api/getUserInfo";
import { isLoading, isLoaded } from "../redux/action/loading";
import { detectDevice } from "../common/detectDevice";
import { postFCM } from "../api/fcm";
import styled from "styled-components";
import { random } from "lodash";
import { insertBaskets } from "../api/baskets";
import { useCheckUser } from "../hook/useCheckUser";

export const FindDivider = styled.div`
  width: 1px;
  height: 10px;
  margin: 3px 6px 0;
  background-color: #3bb77e;
`;

function Login(props) {
  const { showToastAlert, cartItems, clearCart, isLoading, isLoaded, banners } =
    props;
  const router = useRouter();
  const [isNotUser, setIsNotUser] = useState(false);
  const [isUidRemembered, setIsUidRemembered] = useState(false);
  const [inputValues, setInputValues] = useState({
    uid: "",
    upass: "",
  });
  const [interviewBanner, setInterviewBanner] = useState("");

  // 유저 체크
  const { checkUser } = useCheckUser({ isLoading, isLoaded, setIsNotUser });

  // 정보 입력 상태 관리
  const inputHandler = (e) => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  // 아이디 토큰 확인
  const findUidCookie = () => {
    if (getCookie("uid") === undefined) {
      return;
    } else if (getCookie("uid") !== undefined) {
      setInputValues({ uid: getCookie("uid") });
      return setIsUidRemembered(true);
    }
  };

  // 아이디 기억하기 체크 유무
  const rememberUidHandler = () => {
    setIsUidRemembered(!isUidRemembered);
  };

  // 로그인폼 제출
  const submitHandler = async (e) => {
    e.preventDefault();

    if (!inputValues.uid) {
      return showToastAlert("아이디를 입력해주세요!", failToSubmit);
    } else if (!inputValues.upass) {
      return showToastAlert("비밀번호를 입력해주세요!", failToSubmit);
    }

    const body = {
      modes: "Login",
      uid: inputValues.uid,
      upass: inputValues.upass,
    };

    const response = await submitLoginForm(body, isUidRemembered);

    if (response === "Success") {
      var UserAgent = navigator.userAgent;
      var widerplanet_device =
        UserAgent.match(
          /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
        ) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null
          ? "mobile"
          : "web";
      var wptg_tagscript_vars = globalThis.wptg_tagscript_vars || [];
      await wptg_tagscript_vars.push(function () {
        return {
          wp_hcuid: "",
          ti: "54644",
          ty: "Login" /*트래킹태그 타입 */,
          device: widerplanet_device /*디바이스 종류  (web 또는  mobile)*/,
          items: [
            {
              i: "로그인" /*전환 식별 코드  (한글 , 영어 , 번호 , 공백 허용 )*/,
              t: "로그인" /*전환명  (한글 , 영어 , 번호 , 공백 허용 )*/,
              p: "1" /*전환가격  (전환 가격이 없을 경우 1로 설정 )*/,
              q: "1" /*전환수량  (전환 수량이 고정적으로 1개 이하일 경우 1로 설정 )*/,
            },
          ],
        };
      });
      globalThis.wptg_tagscript?.exec();
      showToastAlert("환영합니다!", succeedToSubmit);
      if (cartItems?.length > 0) {
        const body = {
          modes: "InsertSet",
          items: JSON.stringify(cartItems),
        };
        const res = await insertBaskets(body);
        clearCart();
      }

      const currentToken = localStorage.getItem("STORE_FCM_TOKEN");

      if (currentToken) {
        const userAgentData = detectDevice();

        const body = {
          modes: "FcmTokenInsertSet",
          token: currentToken,
          browser: userAgentData.browserName,
          division: userAgentData.deviceType,
        };

        await postFCM(body);
      }

      return router.back();
    } else {
      return showToastAlert(response, failToSubmit);
    }
  };

  useEffect(() => {
    checkUser();
    findUidCookie();
  }, []);

  useEffect(() => {
    if (banners?.interview) {
      setInterviewBanner(
        banners.interview[random(0, banners?.interview.length - 1)]
      );
    }
  }, [banners]);

  return (
    <>
      <Layout parent="홈" subChild="로그인">
        <div className="page-content mt-50 pb-150">
          {isNotUser && (
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 m-auto">
                  <div className="row">
                    <div className="col-lg-6 pr-30 d-none d-lg-block">
                      {interviewBanner && (
                        <img
                          className="border-radius-15"
                          src={interviewBanner?.P_FileUrl}
                          alt={interviewBanner?.img_alt}
                        />
                      )}
                    </div>
                    <div className="col-lg-5 col-md-8 m-auto">
                      <div className="login_wrap widget-taber-content background-white">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1">
                            <h1 className="mb-5">로그인</h1>
                            <p className="mb-30">
                              아직 칼렛스토어 회원이 아니신가요?{" "}
                              <Link href="/register">
                                <a>지금 가입하세요</a>
                              </Link>
                            </p>
                          </div>
                          <form onSubmit={submitHandler}>
                            <div className="form-group">
                              <input
                                name="uid"
                                type="text"
                                required=""
                                placeholder="아이디"
                                defaultValue={inputValues.uid}
                                onChange={inputHandler}
                                className="form-input"
                              />
                            </div>
                            <div className="form-group position-relative">
                              <input
                                id="upass_input"
                                name="upass"
                                type="password"
                                required=""
                                placeholder="비밀번호"
                                onChange={inputHandler}
                                className="form-input"
                                style={{ paddingRight: "40px" }}
                              />
                              <a
                                role="button"
                                onClick={() => {
                                  const input =
                                    document.getElementById("upass_input");
                                  if (input.type === "text") {
                                    input.type = "password";
                                  } else {
                                    input.type = "text";
                                  }
                                }}
                                className="position-absolute"
                                style={{
                                  top: "50%",
                                  right: "10px",
                                  height: "20px",
                                  fontSize: "16px",
                                  translate: "0px -50%",
                                }}
                              >
                                <i className="fi-rs-eye" />
                              </a>
                            </div>
                            <div className="login_footer form-group mb-50 d-flex align-items-center">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox"
                                    id="exampleCheckbox1"
                                    value=""
                                    checked={isUidRemembered}
                                    onChange={rememberUidHandler}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheckbox1"
                                  >
                                    <span>아이디 기억하기</span>
                                  </label>
                                </div>
                              </div>
                              <div className="mb-5 d-flex align-items-center">
                                <Link href="/find/id">
                                  <a>아이디 찾기</a>
                                </Link>
                                <FindDivider />
                                <Link href="/find/password">
                                  <a>비밀번호 찾기</a>
                                </Link>
                              </div>
                            </div>
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-heading btn-block hover-up col-12"
                                name="login"
                              >
                                로그인
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    banners: state.banners,
  };
};

const mapDispatchToProps = {
  showToastAlert,
  clearCart,
  isLoading,
  isLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
