import { useRouter } from "next/router";
import { getUserInfo } from "../api/getUserInfo";

//유저 체크
export const useCheckUser = ({ isLoading, isLoaded, setIsNotUser }) => {
  const router = useRouter();

  const checkUser = async () => {
    isLoading();
    const res = await getUserInfo();

    if (res.code === "0000") {
      isLoaded();
      router.push("/");
    }

    if (res.code !== "0000") {
      setIsNotUser(true);
      isLoaded();
    }
  };

  return { checkUser };
};
