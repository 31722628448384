/*
- attributes

expires: 만료 기간 (date)

maxAge: 클라이언트가 쿠키를 수신한 시점부터 쿠키의 상대적인 최대 수명 (sec)

secure: 웹 브라우저와 웹 서버가 https로 통신하는 경우에만 쿠키 저장

httpOnly: document.cookie라는 자바스크립트 코드로 쿠키에 비정상적으로 접속하는 것을 막는 옵션
*/

export const authCookie = {
  sameSite: "strict",
  path: "/",
  // httpOnly: true,
  // secure: true,
};

export const uidCookie = {
  sameSite: "strict",
  path: "/",
  maxAge: 2592000,
  // httpOnly: true,
  // secure: true,
};
