import http from "./constants/http";
import resources from "./constants/resources";
import { removeCookie, setCookie } from "../common/auth";
import { authCookie, uidCookie } from "../common/cookieOptions";

export const submitLoginForm = async (body, setUid) => {
  const response = await http(resources.MEMBER).post(body);

  const { status } = response;
  const { Code, Data } = response.data;

  if (status === 200) {
    if (Code === "0000") {
      setCookie("token", Data.token, authCookie);
      if (setUid === true) {
        setCookie("uid", body.uid, uidCookie);
      } else {
        removeCookie("uid");
      }
      return "Success";
    } else if (Code === "9999") {
      return Data.Msg;
    }
  } else {
    return console.log("정상적인 요청이 아닙니다. 나중에 다시 시도해주세요.");
  }
};
